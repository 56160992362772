import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto jwt`}</strong>{` -- sign and verify data using JSON Web Tokens (JWT)`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto jwt <subcommand> [arguments] [global-flags] [subcommand-flags]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p>{`A JSON Web Token or JWT (pronounced "jot") is a compact data structure used
to represent some JSON encoded "claims" that are passed as the payload of a
JWS or JWE structure, enabling the claims to be digitally signed and/or
encrypted. The "claims" (or "claim set") are represented as an ordinary JSON
object. JWTs are represented using a compact format that's URL safe and can be
used in space-constrained environments. JWTs can be passed in HTTP
Authorization headers and as URI query parameters.`}</p>
    <p>{`A "claim" is a piece of information asserted about a subject, represented as a
key/value pair. Logically a verified JWT can be interpreted as "`}<inlineCode parentName="p">{`issuer`}</inlineCode>{` says to
`}<inlineCode parentName="p">{`audience`}</inlineCode>{` that `}<inlineCode parentName="p">{`subject`}</inlineCode>{`'s `}<inlineCode parentName="p">{`claim-name`}</inlineCode>{` is `}<inlineCode parentName="p">{`claim-value`}</inlineCode>{`" for each claim.`}</p>
    <p>{`A JWT signed using JWS has three parts:`}</p>
    <ol>
      <li parentName="ol">{`A base64 encoded JSON object representing the JOSE (JSON Object
Signing and Encryption) header that describes the cryptographic
operations applied to the JWT Claims Set`}</li>
      <li parentName="ol">{`A base64 encoded JSON object representing the JWT Claims Set`}</li>
      <li parentName="ol">{`A base64 encoded digital signature of message authentication code`}</li>
    </ol>
    <h2>{`Examples`}</h2>
    <p>{`Create a signed JWT using a JWK (with line breaks for display purposes only):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto jwt sign --key p256.priv.json --iss "joe@example.com" \\
      --aud "https://example.com" --sub auth --exp $(date -v+1M +"%s")
eyJhbGciOiJFUzI1NiIsImtpZCI6IlpqR1g5N0xtY2ZsUG9sV3Zzb0FXekM1V1BXa05GRkgzUWRLTFVXOTc4aGsiLCJ0eXAiOiJKV1QifQ
.
eyJhdWQiOiJodHRwczovL2V4YW1wbGUuY29tIiwiZXhwIjoxNTM1MjM2MTUyLCJpYXQiOjE1MzI1NTc3NTQsImlzcyI6ImpvZUBleGFtcGxlLmNvbSIsIm5iZiI6MTUzMjU1Nzc1NCwic3ViIjoiYXV0aCJ9
.
Z4veKtRmZLoqHNlTrcYo2W1ikLkDcSNfrT52zAGS9cF90Zi3aTXt_75pkikREvMrkC4mhGDdqxCf9ZHq4VnSvg
`}</code></pre>
    <p>{`Create a signed JWT using a JWK and a custom payload:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ echo '{"srv":"https://srv.example.com"}' | step crypto jwt sign \\
      --key p256.priv.json --iss "joe@example.com" \\
      --aud "https://example.com" --sub auth --exp $(date -v+1M +"%s")
eyJhbGciOiJFUzI1NiIsImtpZCI6IlpqR1g5N0xtY2ZsUG9sV3Zzb0FXekM1V1BXa05GRkgzUWRLTFVXOTc4aGsiLCJ0eXAiOiJKV1QifQ
.
eyJhdWQiOiJodHRwczovL2V4YW1wbGUuY29tIiwiZXhwIjoxNTM1MjQyNDcyLCJpYXQiOjE1MzI1NjQwNzMsImlzcyI6ImpvZUBleGFtcGxlLmNvbSIsIm5iZiI6MTUzMjU2NDA3Mywic3J2IjoiaHR0cHM6Ly9zcnYuZXhhbXBsZS5jb20iLCJzdWIiOiJhdXRoIn0
.
DlSkxICjk2h1LarwJgXPbXQe7DwpLMOCvWp3I4GMcBP_5_QYPhVNBPQEeTKAUuQjYwlxZ5zVQnyp8ujvyf1Lqw
`}</code></pre>
    <p>{`Verify the previous token:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ echo $TOKEN | step crypto jwt verify --key p256.pub.json --iss "joe@example.com" --aud "https://example.com"
{
  "header": {
    "alg": "ES256",
    "kid": "ZjGX97LmcflPolWvsoAWzC5WPWkNFFH3QdKLUW978hk",
    "typ": "JWT"
  },
  "payload": {
    "aud": "https://example.com",
    "exp": 1535242472,
    "iat": 1532564073,
    "iss": "joe@example.com",
    "nbf": 1532564073,
    "srv": "https://srv.example.com",
    "sub": "auth"
  },
  "signature": "DlSkxICjk2h1LarwJgXPbXQe7DwpLMOCvWp3I4GMcBP_5_QYPhVNBPQEeTKAUuQjYwlxZ5zVQnyp8ujvyf1Lqw"
}
`}</code></pre>
    <p>{`Read the information in the previous token without verifying it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ echo $TOKEN | step crypto jwt inspect --insecure
{
  "header": {
    "alg": "ES256",
    "kid": "ZjGX97LmcflPolWvsoAWzC5WPWkNFFH3QdKLUW978hk",
    "typ": "JWT"
  },
  "payload": {
    "aud": "https://example.com",
    "exp": 1535242472,
    "iat": 1532564073,
    "iss": "joe@example.com",
    "nbf": 1532564073,
    "srv": "https://srv.example.com",
    "sub": "auth"
  },
  "signature": "DlSkxICjk2h1LarwJgXPbXQe7DwpLMOCvWp3I4GMcBP_5_QYPhVNBPQEeTKAUuQjYwlxZ5zVQnyp8ujvyf1Lqw"
}
`}</code></pre>
    <h2>{`Commands`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "sign/"
              }}>{`sign`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`create a signed JWT data structure`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "verify/"
              }}>{`verify`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`verify a signed JWT data structure and return the payload`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "inspect/"
              }}>{`inspect`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`return the decoded JWT without verification`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      